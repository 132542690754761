import ParentCss from "../../style.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Checkbox, TagsInput, Tooltip } from "nlib/ui";
import { getAccountsData } from "selectors/accounts";
import { getActiveOrganization } from "selectors/organizations";
import { getCurrentQuickBooksRealmId, getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import BusinessesActions from "actions/BusinessesActions";
import Button from "nlib/ui/Button";
import Constants from "const/Constants";
import DatePickerInput from "nlib/common/DatePickerInput";
import IntegrationServices from "const/IntegrationServices";
import React, { useCallback, useMemo, useState } from "react";
import RpaTransactionTypes from "const/RpaTransactionTypes";
import SelectUncategorizedCategoryNames from "nlib/common/SelectUncategorizedCategoryNames";
import Step from "../Step";
import Utils from "utils/Utils";
import moment from "moment";

const { DATETIME_FORMATS } = Constants;

const RpaSettingsStep = ({ index, currentStep }) => {
  const dispatch = useDispatch();

  const { messages, uiTexts } = useSelector(getTextsData);

  const quickBooksBusiness = useSelector(getCurrentQuickBooksRealmId);

  const { appsumoValue, ignoreUncategorizedTransactions } = useSelector(getActiveOrganization);

  const {
    id: selectedBusinessId,
    extraData: { integrationService, quickBooksBookCloseDate } = {},
    settings: {
      uncategorizedCategoryNames: initialUncategorizedCategoryNames = [],
      rpaStartFrom: initialRpaStartFrom = ""
    } = {}
  } = useSelector(getSelectedBusinessData);

  const accountsData = useSelector(getAccountsData);

  const accountNames = useMemo(() => {
    return accountsData
      .filter(({ extraData: { paymentAccount } = {} }) => !quickBooksBusiness || paymentAccount)
      .map(({ name }) => name.trim().replace(/\s+/g, " "));
  }, [quickBooksBusiness, accountsData]);

  const accountNameOptions = useMemo(() => {
    return accountNames.map((name) => ({ value: name, label: name }));
  }, [accountNames]);

  const [fetching, setFetching] = useState(false);

  const [rpaAutoApprove, setRpaAutoApprove] = useState(true);

  const [rpaExcludedAccountsToggle, setRpaExcludedAccountsToggle] = useState(true);

  const [processExistingUncategorized, setProcessExistingUncategorized] = useState(true);

  const [rpaExcludedWords, setRpaExcludedWords] = useState([]);

  const [rpaExcludedWordsToggle, setRpaExcludedWordsToggle] = useState(true);

  const [allowAutoCreatePayee, setAllowAutoCreatePayee] = useState(true);

  const [allowApproveWithoutPayee, setAllowApproveWithoutPayee] = useState(true);

  const [rpaExcludedAccounts, setRpaExcludedAccounts] = useState([]);

  const [uncategorizedCategoryNames, setUncategorizedCategoryNames] = useState(initialUncategorizedCategoryNames);

  const [rpaTransactionTypes, setRpaTransactionTypes] = useState(RpaTransactionTypes.BOTH);

  const [rpaMatchingEnabled, setRpaMatchingEnabled] = useState(true);

  const minDate = useMemo(() => {
    return moment().subtract(1, "years").startOf("year");
  }, []);

  const [rpaStartFrom, setRpaStartFrom] = useState(() => {
    return initialRpaStartFrom || (quickBooksBookCloseDate
      ? moment.utc(quickBooksBookCloseDate).add(1, "days").format(DATETIME_FORMATS.API_DATE)
      : minDate.format(DATETIME_FORMATS.API_DATE));
  });

  const dateError = moment(rpaStartFrom).isBefore(minDate);

  const [
    titleText,
    descriptionText,
    excludeAccountsPlaceholderText,
    appsumoExtraText
  ] = messages.onboardingRpaSettings;

  const [matchTransactionsQboText, matchTransactionsXeroText] = messages.settingsMatchTransactions;

  const matchTransactionsText = useMemo(() => {
    return integrationService === IntegrationServices.QUICK_BOOKS.value ? matchTransactionsQboText : matchTransactionsXeroText;
  }, [integrationService, matchTransactionsQboText, matchTransactionsXeroText]);

  const handleRpaAutoApproveToggleChange = useCallback((value) => {
    setRpaAutoApprove(value);
  }, []);

  const handleRpaExcludedAccountsToggleChange = useCallback((value) => {
    setRpaExcludedAccountsToggle(value);
    setRpaExcludedAccounts([]);
  }, []);

  const handleUncategorizedToggleChange = useCallback((value) => {
    setProcessExistingUncategorized(value);
  }, []);

  const handleUncategorizedInputChange = useCallback((value) => {
    setUncategorizedCategoryNames(value);
  }, []);

  const handleRpaExcludedWordsToggleChange = useCallback((value) => {
    setRpaExcludedWordsToggle(value);
  }, []);

  const handleRpaExcludedWordsInputChange = useCallback((value) => {
    setRpaExcludedWords(value);
  }, []);

  const handleAllowApproveWithoutPayeeToggleChange = useCallback((value) => {
    setAllowApproveWithoutPayee(value);
  }, []);

  const handleAllowAutoCreatePayeeToggleChange = useCallback((value) => {
    setAllowAutoCreatePayee(value);
  }, []);

  const handleRpaExcludedAccountsInputChange = useCallback((value) => {
    setRpaExcludedAccounts(
      value.filter((item) => item && accountNames.includes(item))
    );
  }, [accountNames]);

  const handleWhoAskedButtonClick = useCallback((event, value) => {
    setRpaTransactionTypes(value);
  }, []);

  const handleRpaStartFromInputChange = useCallback((value) => {
    setRpaStartFrom(value);
  }, []);

  const handleRpaMatchingToggleChange = useCallback((value) => {
    setRpaMatchingEnabled(value);
  }, []);

  const handleContinueButtonClick = useCallback(async() => {
    setFetching(true);

    await dispatch(
      BusinessesActions.editBusinessSettings(
        selectedBusinessId,
        {
          rpaAutoApprove,
          rpaExcludedAccounts,
          rpaTransactionTypes,
          allowAutoCreatePayee,
          rpaMatchingEnabled,
          rpaExcludedWords: rpaExcludedWordsToggle ? [] : rpaExcludedWords,
          ...(quickBooksBusiness && !ignoreUncategorizedTransactions && {
            uncategorizedCategoryNames: processExistingUncategorized
              ? initialUncategorizedCategoryNames : uncategorizedCategoryNames
          }),
          allowApproveWithoutPayee,
          rpaStartFrom: Utils.formatNoTimeZoneDate(rpaStartFrom)
        },
        true
      )
    );

    setFetching(false);
  }, [
    rpaStartFrom,
    rpaAutoApprove,
    rpaExcludedWords,
    selectedBusinessId,
    quickBooksBusiness,
    rpaMatchingEnabled,
    rpaExcludedAccounts,
    rpaTransactionTypes,
    allowAutoCreatePayee,
    rpaExcludedWordsToggle,
    allowApproveWithoutPayee,
    uncategorizedCategoryNames,
    processExistingUncategorized,
    ignoreUncategorizedTransactions,
    initialUncategorizedCategoryNames,
    dispatch
  ]);

  return (
    <Step
      number={index + 1}
      className={Css.step}
      active={currentStep === index}
      success={currentStep > index}>
      <div className={ParentCss.row}>
        <div className={ParentCss.col}>
          <div className={ParentCss.subtitle}>{titleText}</div>
          {currentStep === index && (
            <>
              <div className={ParentCss.text}>{descriptionText}</div>
              <div className={ParentCss.buttons}>
                <Checkbox
                  toggle
                  checked={rpaAutoApprove}
                  label={Utils.replaceTextVars(
                    messages.autoExportTransactionsToggleLabelText,
                    { system: IntegrationServices.getByValue(integrationService).label }
                  )}
                  onChange={handleRpaAutoApproveToggleChange} />
              </div>
              <div className={ParentCss.buttons}>
                <Checkbox
                  toggle
                  checked={allowAutoCreatePayee}
                  label={messages.autoCreatePayeeToggleLabelText}
                  onChange={handleAllowAutoCreatePayeeToggleChange} />
              </div>
              <div className={ParentCss.buttons}>
                <Checkbox
                  toggle
                  checked={allowApproveWithoutPayee}
                  label={messages.approveWithoutPayeeToggleLabelText}
                  onChange={handleAllowApproveWithoutPayeeToggleChange} />
              </div>
              <div className={ParentCss.buttons}>
                <Checkbox
                  toggle
                  checked={rpaMatchingEnabled}
                  label={matchTransactionsText}
                  onChange={handleRpaMatchingToggleChange} />
              </div>
              <div className={ParentCss.buttons}>
                <Checkbox
                  toggle
                  checked={rpaExcludedAccountsToggle}
                  label={messages.processAllAccountsToggleLabelText}
                  onChange={handleRpaExcludedAccountsToggleChange} />
              </div>
              {!rpaExcludedAccountsToggle && (
                <div className={ParentCss.buttons}>
                  <div className={ParentCss.block}>
                    <div className={ParentCss.label}>{messages.settingsExcludedAccountsText}</div>
                    <TagsInput
                      className={Css.tagsInput}
                      value={rpaExcludedAccounts}
                      invalid={!rpaExcludedAccounts.length}
                      autoCompleteData={accountNameOptions}
                      onChange={handleRpaExcludedAccountsInputChange}
                      placeholder={excludeAccountsPlaceholderText} />
                  </div>
                </div>
              )}
              {quickBooksBusiness && !ignoreUncategorizedTransactions && (
                <>
                  <div className={ParentCss.buttons}>
                    <Checkbox
                      toggle
                      checked={processExistingUncategorized}
                      label={messages.onboardingUncategorizedToggleLabelText}
                      onChange={handleUncategorizedToggleChange} />
                  </div>
                  {!processExistingUncategorized && (
                    <>
                      <div className={ParentCss.buttons}>
                        <div className={ParentCss.block}>
                          <div className={ParentCss.label}>{messages.settingsUncategorizedLabel}</div>
                          <SelectUncategorizedCategoryNames
                            className={Css.tagsInput}
                            value={uncategorizedCategoryNames}
                            invalid={!uncategorizedCategoryNames.length}
                            onChange={handleUncategorizedInputChange} />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              <div className={ParentCss.buttons}>
                <Checkbox
                  toggle
                  checked={rpaExcludedWordsToggle}
                  label="Process All Transactions (No Exclusions)"
                  onChange={handleRpaExcludedWordsToggleChange} />
              </div>
              {!rpaExcludedWordsToggle && (
                <>
                  <div className={ParentCss.buttons}>
                    <div className={ParentCss.block}>
                      <div className={ParentCss.label}>{messages.rpaExcludedWordsLabel}</div>
                      <div className={Css.excludeWords}>
                        <TagsInput
                          value={rpaExcludedWords}
                          className={Css.tagsInput}
                          invalid={!rpaExcludedWords?.length}
                          placeholder={messages.rpaExcludedWordsPlaceholder}
                          onChange={handleRpaExcludedWordsInputChange} />
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className={ParentCss.buttons}>
                <div className={ParentCss.block}>
                  <div className={ParentCss.label}>{messages.chooseTransactionsType}</div>
                  <div className={ParentCss.row}>
                    <Button
                      outline large block
                      loading={fetching}
                      primary={rpaTransactionTypes === RpaTransactionTypes.BOTH}
                      value={RpaTransactionTypes.BOTH}
                      className={ParentCss.button}
                      onClick={handleWhoAskedButtonClick}>
                      <span>{uiTexts.incomeAndExpenses}</span>
                    </Button>
                    <Button
                      outline large block
                      loading={fetching}
                      primary={rpaTransactionTypes === RpaTransactionTypes.INCOME}
                      value={RpaTransactionTypes.INCOME}
                      className={ParentCss.button}
                      onClick={handleWhoAskedButtonClick}>
                      <span>{uiTexts.incomeOnly}</span>
                    </Button>
                    <Button
                      outline large block
                      loading={fetching}
                      primary={rpaTransactionTypes === RpaTransactionTypes.EXPENSES}
                      value={RpaTransactionTypes.EXPENSES}
                      className={ParentCss.button}
                      onClick={handleWhoAskedButtonClick}>
                      <span>{uiTexts.expensesOnly}</span>
                    </Button>
                  </div>
                </div>
              </div>
              <div className={Css.label}>{messages.onboardingRpaStartFrom}</div>
              <div className={ParentCss.row}>
                <div className={ParentCss.col}>
                  <div className={Css.dateWrap}>
                    <Tooltip className={Css.tooltip} opened={dateError}>
                      <Icons.Warning />
                      <span>
                        {Utils.replaceTextVars(messages.rpaMinDateWarning, { date: minDate.format(DATETIME_FORMATS.DATE_TEXT) })}
                      </span>
                    </Tooltip>
                    <DatePickerInput
                      value={rpaStartFrom}
                      minDate={minDate}
                      error={!rpaStartFrom || dateError}
                      invalid={!rpaStartFrom || dateError}
                      placeholder={messages.onboardingRpaStartFromPlaceholder}
                      onChange={handleRpaStartFromInputChange} />
                  </div>
                </div>
                <Button
                  primary large block
                  loading={fetching}
                  disabled={dateError
                    || !rpaTransactionTypes
                    || !rpaStartFrom
                    || (!rpaExcludedAccountsToggle && !rpaExcludedAccounts?.length)
                    || (!rpaExcludedWordsToggle && !rpaExcludedWords?.length)}
                  className={ParentCss.likeButton}
                  onClick={handleContinueButtonClick}>{uiTexts.continue}</Button>
              </div>
            </>
          )}
        </div>
        {currentStep > index && (
          <div className={`${ParentCss.likeButton} ${ParentCss.success}`}>
            <Icons.Check weight="bold" />
            <span>{uiTexts.selected}</span>
          </div>
        )}
      </div>
      {currentStep === index && !!appsumoValue && (
        <div className={ParentCss.warning}>
          <Icons.WarningCircle />
          <span className={ParentCss.text}>{Utils.replaceTextVars(appsumoExtraText, { months: appsumoValue })}</span>
        </div>
      )}
    </Step>
  );
};

export default React.memo(RpaSettingsStep);

import * as Icons from "@phosphor-icons/react";
import Css from "./style.module.scss";
import React from "react";

const InfoBanner = ({ text }) => {
  return (
    <div className={Css.infoBanner}>
      <Icons.Warning className={Css.icon} />
      <span>{text}</span>
    </div>
  );
};

export default React.memo(InfoBanner);
